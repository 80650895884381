import React, {useEffect, useState} from 'react'
import { Router, navigate } from '@reach/router'
import { Provider } from "react-redux"
import ReduxStore from "../components/app/store"
import UserCalendar from "../components/app/features/request/user_calendar"
import hasParams from "../components/has_params"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { ElementOrLoader } from "../components/util"
import axios from "axios"
import { API_ADDRESS } from "../lib/constants"
import { getAuthToken } from "../lib/auth"

import AdminUser from "../components/app/features/admin/user"
import AdminTopics from "../components/app/features/admin/topics"

const OldAdminDashboard = (props) => {

  const [title, setTitle] = useState('Loading Calendar');
  useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${getAuthToken()}` }
    };
    // axios.get(`${API_ADDRESS}/admin/is-admin`, config)
    //   .then((response) => {
    //
    //   });
  }, []);

  return (
    <Provider store={ReduxStore}>
      <SEO title="Loading Calendar"/>
      <div>
        <Router>
          <AdminUser path="/greenwoodx_admin_2020/users" />
          <AdminTopics path="/greenwoodx_admin_2020/topics"/>
        </Router>
      </div>
    </Provider>
  );
}

export default hasParams(OldAdminDashboard);
