import React, {useState, useEffect} from 'react';
import {
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Collapse
} from "@material-ui/core"
import axios from "axios"
import { API_ADDRESS, GREENWOODX_NETWORK_KEY, USER_PHASE } from "../../../../lib/constants"
import { makeStyles } from "@material-ui/core/styles"
import { getAuthToken } from "../../../../lib/auth"
import Util from "../../../../lib/util"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { ElementOrLoader } from "../../../util"

const useStyle = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(5)
  },
  chips: {
    display: 'flex',
      flexWrap: 'wrap',
  },
  topicSectionBox: {
    margin: theme.spacing(2, 1)
  },
  expansionDetailOverride: {
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(.25, .25),

  },
  selectedCount: {
    background: theme.palette.primary.main,
    color: 'white',
    width: '20px',
    height: '20px',
    fontSize: '12px'
  }
}));


const AdminTopics = () => {
  const classes = useStyle();
  const [expanded, setExpanded] = useState('');
  const [userView, setUserView] = useState('');
  const [topics, setTopics] = useState({})
  const [topicKeys, setTopicKeys] = useState([]);
  const [sorted, setSorted] = useState([])
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${getAuthToken()}` }
    };
    axios.get(`${API_ADDRESS}/admin/get-topics?networkKey=${GREENWOODX_NETWORK_KEY}`, config)
      .then((response) => {
        setTopics(response.data.data);
        setTopicKeys(Object.keys(response.data.data));

        const t = response.data.data;
        const tK = Object.keys(t);

        setSorted(tK.map((key) => t[key]).sort((a, b) => Object.keys(b.n).length - Object.keys(a.n).length));
        setLoading(false);
      });


  }, [])

  const handleExpandedChange = (code) => {
    setExpanded(expanded==code? '' : code);
  };

  const handleUserView = (code) => {
    setUserView(userView==code? '' : code);
  };

  const TopicTable = ({topic}) => {
    const nKeys = Object.keys(topic.n);
    const sKeys = Object.keys(topic.s);
    return (
      <React.Fragment>
        <TableRow>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => handleExpandedChange(topic.topic)}>
              {expanded == topic.topic ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{topic.name}</TableCell>
          <TableCell>{Object.keys(topic.n).length}</TableCell>
          <TableCell>{Object.keys(topic.s).length}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={3} style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Collapse in={expanded == topic.topic} timeout="auto" unmountOnExit>
              <List>
                <ListItem
                  button
                  onClick={() => setUserView(userView == 'n' ? '': 'n')}
                >
                  <ListItemText primary="Professional" />
                  {userView == 'n' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </ListItem>
                <Collapse in={userView== 'n'} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {
                      nKeys.map((key)=>
                        <ListItem key={key}>
                          <ListItemText primary={`${Util.getUserFullName(topic.n[key])} • ${topic.n[key].Email} • ${Object.keys(USER_PHASE)[topic.n[key].Phase-1]}`} />
                        </ListItem>
                      )
                    }
                    <ListItem>

                    </ListItem>
                  </List>
                </Collapse>
                <ListItem
                  button
                  onClick={() => setUserView(userView == 's' ? '': 's')}
                >
                  <ListItemText primary="Students" />
                  {userView == 's' ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </ListItem>
                <Collapse in={userView== 'n'} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {
                      sKeys.map((key)=>
                        <ListItem key={key}>
                          <ListItemText primary={`${Util.getUserFullName(topic.s[key])} • ${topic.s[key].Email} • ${Object.keys(USER_PHASE)[topic.s[key].Phase-1]}`} />
                        </ListItem>
                      )
                    }
                    <ListItem>

                    </ListItem>
                  </List>
                </Collapse>
              </List>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }

  return (
    <Box className={classes.container}>
      <Typography variant="body2">
        Topics
      </Typography>
      <Grid item xs={12}>
        <ElementOrLoader loading={loading}>

          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Label</TableCell>
                  <TableCell>Normal (intersection)</TableCell>
                  <TableCell>Students</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  sorted.map((topic) => <TopicTable key={topic.topic} topic={topic} />)
                }
              </TableBody>
            </Table>
          </TableContainer>
        </ElementOrLoader>
      </Grid>
    </Box>
  );
}

export default AdminTopics;
