import React, {useState, useEffect} from 'react';
import {
  Paper,
  Avatar,
  Box,
  Checkbox,
  Chip,
  Button,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary, FormControlLabel, Grid, ListSubheader, MenuItem, TextField,
  Typography,

  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,

  List,
  ListItem,
  ListItemText,
  Collapse
} from "@material-ui/core"
import axios from "axios"
import {
  API_ADDRESS,
  APP_BAR_STATE,
  GREENWOODX_NETWORK_KEY,
  TOPIC_MEDICINE_ID,
  USER_PHASE,
} from "../../../../lib/constants"
import { makeStyles } from "@material-ui/core/styles"
import { useDispatch, useSelector } from "react-redux"
import { setAppBar, setOnboardData } from "../system/system_slice"
import { getAuthToken } from "../../../../lib/auth"
import Util from "../../../../lib/util"
import { ElementOrLoader } from "../../../util"

const useStyle = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(5)
  },
  chips: {
    display: 'flex',
      flexWrap: 'wrap',
  },
  topicSectionBox: {
    margin: theme.spacing(2, 1)
  },
  expansionDetailOverride: {
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(.25, .25),

  },
  selectedCount: {
    background: theme.palette.primary.main,
    color: 'white',
    width: '20px',
    height: '20px',
    fontSize: '12px'
  }
}));


const AdminUser = () => {
  const classes = useStyle();
  const [expanded, setExpanded] = useState('');
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${getAuthToken()}` }
    };
    axios.get(`${API_ADDRESS}/admin/get-users?networkKey=${GREENWOODX_NETWORK_KEY}`, config)
      .then((response) => {
        setUsers(response.data.users);
        setLoading(false);
      });


  }, [])

  const handleExpandedChange = (code) => {
    setExpanded(expanded==code? '' : code);
  };

  const takeOffWaitlist = (user) => {
    const config = {
      headers: { Authorization: `Bearer ${getAuthToken()}` }
    };

    const data = {
      id: user['id'],
      network_key: GREENWOODX_NETWORK_KEY
    }
    axios.post(`${API_ADDRESS}/admin/off-waitlist`, data, config)
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error);
          return alert(response.data.error_message);
        }
        alert("Success");
      }).catch((error) => alert(error));
  }

  const enableExpandNetwork = (user) => {
    const config = {
      headers: { Authorization: `Bearer ${getAuthToken()}` }
    };

    const data = {
      id: user['id'],
      network_key: GREENWOODX_NETWORK_KEY
    }
    axios.post(`${API_ADDRESS}/admin/toggle-expand-network`, data, config)
      .then((response) => {
        if (response.data.error) {
          alert(response.data.error);
          return alert(response.data.error_message);
        }
        alert("Success");
      }).catch((error) => alert(error));
  }

  const UserListItem = ({user}) => {
    return (
      <React.Fragment>
        <ListItem
          button
          key={user['id']}
          onClick={() => handleExpandedChange(user['id'])}
        >
          <ListItemText
            primary={`${Util.getUserFullName(user)} • ${user.Email} • ${Object.keys(USER_PHASE)[user.Phase-1]}`} />
        </ListItem>
        <Collapse in={expanded === user['id']} timeout="auto" unmountOnExit>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Key</TableCell>
                  <TableCell>Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  Object.keys(user).map((key) => (
                    <TableRow key={key}>
                      <TableCell>{key}</TableCell>
                      <TableCell>{user[key].toString()}</TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </TableContainer>
          {
            user.Phase == USER_PHASE.WAITLIST?
              <Button
                variant="contained"
                onClick={() => takeOffWaitlist(user)}
              >
                Take Off Waitlist
              </Button> : null
          }
          <Button
            variant="contained"
            onClick={() => enableExpandNetwork(user)}
          >
            {user.ExpandNetworkEnabled? 'Turn Off' : 'Turn On'} ExpandNetwork
          </Button>
        </Collapse>

      </React.Fragment>
    )
  }

  return (
    <Box className={classes.container}>
      <Typography variant="body2">
        Users
      </Typography>
      <Grid item xs={12}>
        <ElementOrLoader loading={loading}>
          {
            users.map((user) => <UserListItem key={user['id']} user={user}/>)
          }
        </ElementOrLoader>
      </Grid>
    </Box>
  );
}

export default AdminUser;
